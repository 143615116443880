import { ActivatedRouteSnapshot } from '@angular/router';

export const getParamFromRouteOrParent = (
  route: ActivatedRouteSnapshot,
  paramName: string
): string | null => {
  const val = route.params[paramName];
  if (val) {
    return val;
  }

  if (route.parent) {
    return getParamFromRouteOrParent(route.parent, paramName);
  }

  return null;
};
